<template>
  <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <div class="row" v-if="loading">
      <div class="placeholder-paragraph aligned-centered">
          <div class="line"></div>
          <div class="line"></div>
        </div>
    </div>

    <div class="media d-block d-lg-flex" v-if="!loading">
      <div class="profile-sidebar pd-lg-r-25">
        <div class="row">
          <div class="col-12">
            <div class="avatar avatar-xxl">
              <img v-bind:src="church.logoUrl" v-bind:alt="church.name">
            </div>
          </div><!-- col -->
          <div class="col-sm-8 col-md-7 col-lg mg-t-20 mg-sm-t-0 mg-lg-t-25">
            <h5 class="mg-b-25 tx-spacing--1">{{church.name}}</h5>
            <div class="d-flex mg-b-25">
              <button class="btn btn-xs btn-white flex-fill">{{ $t('message') }}</button>

              <button class="btn btn-xs btn-primary flex-fill mg-l-10" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" v-if="!user">{{ $t('follow') }}</button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="wd-200 pd-15">
                  <p>To Follow a church please login or create an account</p>
                  <nav class="nav">
                    <router-link :to="{name: 'login'}" class="nav-link">{{ $t('login') }}</router-link>
                    <router-link :to="{name: 'register'}" class="nav-link">{{ $t('register') }}</router-link>
                  </nav>
                </div>
              </div>

              <button class="btn btn-xs btn-primary flex-fill mg-l-10" v-if="user && !following" v-on:click="follow">{{ $t('follow') }}</button>
              <button class="btn btn-xs btn-primary flex-fill mg-l-10" v-if="user && following" v-on:click="unfollow">{{ $t('following') }}</button>
            </div>

            <p class="tx-13 tx-color-02 mg-b-25">
              {{church.description}}
            </p>

            <div class="d-flex">
              <div class="profile-skillset flex-fill">
                <h4>
                  <a href class="link-01">{{church.follow_count|smart_num}}</a>
                </h4>
                <label>{{ $t('followers') }}</label>
              </div>
              <div class="profile-skillset flex-fill">
                <h4>
                  <a href class="link-01">{{church.view_count|smart_num}}</a>
                </h4>
                <label>{{ $t('views') }}</label>
              </div>
            </div>
          </div><!-- col -->
          
          <div class="col-sm-6 col-md-5 col-lg mg-t-40">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
              Websites &amp; Social Channels
            </label>
            <ul class="list-unstyled profile-info-list">
              <li v-if="church.website">
                <FeatherIcon icon="globe"></FeatherIcon>
                <a :href="church.website" target="_blank">{{website}}</a>
              </li>
              <li v-if="church.twitter">
                <FeatherIcon icon="twitter"></FeatherIcon>
                <a :href="twitterUrl" target="_blank">@{{church.twitter}}</a>
              </li>
              <li v-if="church.instagram">
                <FeatherIcon icon="instagram"></FeatherIcon>
                <a :href="instagramUrl" target="_blank">@{{church.instagram}}</a>
              </li>
              <li v-if="church.facebook">
                <FeatherIcon icon="facebook"></FeatherIcon>
                <a :href="facebookUrl" target="_blank">@{{church.facebook}}</a>
              </li>
            </ul>
          </div><!-- col -->
          <div class="col-sm-6 col-md-5 col-lg-12 mg-t-40">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
              Contact Information
            </label>
            <ul class="list-unstyled profile-info-list">
              <li v-if="church.address || church.city || church.state">
                <i data-feather="home"></i>
                <div class="tx-color-03">{{church.address}}<br v-if="church.address"/>{{church.city}} {{church.state}}</div>
              </li>
              <li v-if="church.phone">
                <i data-feather="phone"></i>
                <a href>{{church.phone}}</a>
              </li>
              <li v-if="church.email">
                <i data-feather="mail"></i>
                <a :href="'mailto:' + church.email">Contact Us</a>
              </li>
            </ul>
          </div><!-- col -->
          <div class="col-sm-6 col-md-5 col-lg mg-t-40">
            <label class="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Topics</label>
            <ul class="list-inline list-inline-skills">
              <template v-for="topic in church.topics">
                <li class="list-inline-item" v-bind:key="topic.id">
                  <router-link :to="{name: 'topics.show', params: {id: topic.id, text: topic.topic}}">
                    {{topic.text}}
                  </router-link>
                </li>
              </template>
            </ul>
          </div><!-- col -->
        </div><!-- row -->
      </div><!-- profile-sidebar -->

      <div class="media-body">
        <sermon-grid :limit="9" :query="{church_id: church.id}"></sermon-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import axios from 'axios'
import _ from 'lodash'
import FeatherIcon from '~/components/FeatherIcon'
import SermonGrid from '~/components/SermonGrid'

export default {
  layout: "default",
  metaInfo() {
    return { title: this.title };
  },
  data: () => ({
    title: window.config.appName,
    loading: true
  }),
  components: {
    FeatherIcon,
    'sermon-grid': SermonGrid
  },
  created () {
    const church = this.churches[this.$route.params.id];
    if (!church) {
      this.$store.dispatch('public/fetchChurch', {id: this.$route.params.id})
        .then((church) => { 
          this.loading = false; 
          this.title = church.name
        });
    } else {
      this.loading = false;
      this.title = church.name;
    }
  },
  computed: {
    website () {
      return this.church.website.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '').replace(/\/$/, '');
    },
    twitterUrl: function() { return 'https://twitter.com/' + this.church.twitter; },
    facebookUrl: function() { return 'https://facebook.com/' + this.church.facebook; },
    instagramUrl: function() { return 'https://instagram.com/' + this.church.instagram; },
    following () {
      return this.user && 
        this.user.following.length > 0 && 
        _.indexOf(this.user.following, this.church.id) !== -1;
    },
    church () {
      return this.churches[this.$route.params.id];
    },
    ...mapGetters({
      user: 'auth/user',
      churches: 'public/churches'
    })
  },
  methods: {
    async follow() {
      const { data } = await axios.post('/api/v1/user/me/follow/' + this.$route.params.id);
      
      this.$store.dispatch('auth/updateUser', {user: data.user});
    },
    async unfollow() {
      const { data } = await axios.post('/api/v1/user/me/unfollow/' + this.$route.params.id);
      
      this.$store.dispatch('auth/updateUser', {user: data.user});
    }
  }
};
</script>

<style lang="scss" scoped>
  .avatar, .avatar img {
    max-height: 100px;
    width: initial; 
    max-width: 100%;
    object-fit: contain;
  }
</style>

<style lang="scss" scoped>
.sermon-grid {
  ::v-deep .card-img-top {
    background: no-repeat center center;
    background-size: cover;
    height: 50vw;
    @include media-breakpoint-up(sm) {
      height: 8rem;
    }
    @include media-breakpoint-up(md) {
      height: 11rem;
    }
    @include media-breakpoint-up(lg) {
      height: 7rem;
    }
    @include media-breakpoint-up(xl) {
      height: 9rem;
    }
  }
}
</style>
